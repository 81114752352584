* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #e4d8c0;
  color: #212121;
}

 body, input, button, textarea {
   font: 400 16px 'Roboto', sans-serif;
 }